<template>
  <el-dialog
    :title="`Assign location area(s) to ${gardener.name}`"
    :visible.sync="shouldShow"
    width="30%"
    append-to-body
    @close="closeEvent"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Location area(s)"
            prop="location_area_id"
            :rules="validateField()"
          >
            <el-select
              v-model="form.location_area_id"
              filterable
              multiple
              placeholder="Select location area(s)"
              @change="setLocationAreaName"
            >
              <el-option
                v-for="area in locationareas"
                :key="area.id"
                :label="area.name"
                :value="area.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="adding" @click="assign"
        >Assign</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import locationareas from "@/requests/zones/locationareas";
import * as actions from "@/store/action-types";

export default {
  name: "GardenerLocationAreaAdd",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    gardener: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        location_area_id: [],
      },
      locationAreas: [],
      adding: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
  },
  watch: {
    show() {
      if (this.show && !this.locationareas.length) {
        this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    setLocationAreaName() {
      const locationAreas = this.locationareas.filter((area) =>
        this.form.location_area_id.includes(area.id)
      );
      if (locationAreas.length) {
        this.locationAreas = locationAreas;
      }
    },
    assign() {
      const payload = {
        gardener_id: this.gardener.id,
        location_area_id: this.form.location_area_id,
      };

      this.adding = true;
      locationareas
        .gardener("update", payload)
        .then((response) => {
          if (response.data.status) {
            this.$emit("success", this.locationAreas);
            this.$message.success(response.data.message);
            this.adding = false;
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.adding = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
